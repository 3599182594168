<template>
  <el-row>
    <el-col :span="24">
      <!-- 搜索 -->
      <el-form :inline="true" :model="searchForm" ref="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.title" placeholder="请输入标题" @keyup.enter.native="handleSearch" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.sensitiveWordKey" placeholder="请输入敏感词KEY" @keyup.enter.native="handleSearch" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="100"></el-table-column>
        <el-table-column prop="sensitiveWordKey" label="敏感词KEY" width="200"></el-table-column>
        <el-table-column prop="title" label="标题" width="300"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="240">
          <template slot-scope="scope">
            <el-button size="mini" @click="seeDetail(scope.row)">查看</el-button>
            <el-button size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button size="mini" @click="deleteSubmit(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="page"
        :page-size="size"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>
    <!-- 添加编辑 -->
    <el-dialog :title="addEditTitle" :visible.sync="addEditDialog" :close-on-click-modal="false" width="1000px">
      <el-form :model="addEditForm" ref="addEditForm" :rules="rules" label-width="100px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="addEditForm.title" placeholder="请输入标题" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="敏感词KEY" prop="sensitiveWordKey">
          <el-input v-model.trim="addEditForm.sensitiveWordKey" placeholder="请输入敏感词KEY" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="addEditForm.content" placeholder="请输入敏感词内容" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100000" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button v-if="addEditStatus === 1" type="primary" @click="editSubmit">保存</el-button>
        <el-button v-else type="primary" @click="addSubmit">保存</el-button>
        <el-button @click="addEditDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="detailDialog" width="800px">
      <el-form :model="detailData" ref="detailData" label-width="100px">
        <el-form-item label="标题">
          {{ detailData.title }}
        </el-form-item>
        <el-form-item label="敏感词KEY">
          {{ detailData.sensitiveWordKey }}
        </el-form-item>
        <el-form-item label="内容">
          <div v-html="detailData.content"></div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="detailDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>
<script>
import API from '@/api/system/api_sensitiveword'
import _ from 'lodash'
import PagesMixins from '@/mixins/pagesMixins'
const searchForm = {
  title: null,
  sensitiveWordKey: null
}
let addEditForm = {
  title: '',
  sensitiveWordKey: '',
  content: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: null,
      addEditStatus: null,
      addEditTitle: '',
      addEditDialog: false,
      addEditForm: _.cloneDeep(addEditForm),
      rules: {
        title: [{ required: true, message: '请输入敏感词标题', trigger: 'blur' }],
        sensitiveWordKey: [{ required: true, message: '请输入敏感词KEY', trigger: 'blur' }],
        content: [{ required: true, message: '请输入敏感词内容', trigger: 'blur' }]
      },
      detailDialog: false,
      detailData: _.cloneDeep(addEditForm)
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.page = 1
      this.getListData()
    },
    getListData() {
      let params = {
        ...this.searchForm,
        page: this.page,
        size: this.size
      }
      API.querySensitiveWordList(params).then(res => {
        if (res && res.code === '000000') {
          this.listData = _.cloneDeep(res.data.list)
          this.total = res.data.total
        }
      })
    },
    reset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    add() {
      this.addEditStatus = 0
      this.addEditTitle = '新增'
      this.addEditDialog = true
      this.addEditForm = _.cloneDeep(addEditForm)
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
    },
    addSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = { ...this.addEditForm }
          API.addSensitiveWord(params).then(res => {
            if (res && res.code === '000000') {
              this.$message.success({ message: res.msg || '新增成功', duration: 2000 })
              this.addEditDialog = false
              this.handleSearch()
            } else {
              this.$message.error({ message: res.msg, duration: 2000 })
            }
          })
        } else {
          return false
        }
      })
    },
    edit(row) {
      this.addEditStatus = 1
      this.addEditTitle = '编辑'
      this.addEditDialog = true
      this.addEditForm = _.cloneDeep(row)
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
    },
    editSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = {
            isDelete: 0,
            ...this.addEditForm
          }
          API.editSensitiveWord(params).then(res => {
            if (res && res.code === '000000') {
              this.$message.success({ message: res.msg || '编辑成功', duration: 2000 })
              this.addEditDialog = false
              this.getListData()
            } else {
              this.$message.error({ message: res.msg, duration: 2000 })
            }
          })
        } else {
          return false
        }
      })
    },
    deleteSubmit(row) {
      this.$confirm('确定要删除此条数据吗?', '提示', {
        confirmButtonClass: 'el-button--warning'
      }).then(() => {
        API.deleteSensitiveWord({ sensitiveWordId: row.sensitiveWordId }).then(res => {
          if (res && res.code === '000000') {
            this.$message.success({ message: res.msg || '删除成功', duration: 2000 })
            this.getListData()
          } else {
            this.$message.error({ message: res.msg, duration: 2000 })
          }
        })
      })
    },
    seeDetail(row) {
      this.detailDialog = true
      this.detailData = row
    }
  }
}
</script>
