import * as http from '../http'

export default {
  //查询获取 敏感词列表(通过page分页)
  querySensitiveWordList: params => {
    return http.post('/sensitiveword/querySensitiveWordList', params)
  },

  //新增敏感词
  addSensitiveWord: params => {
    return http.post('/sensitiveword/addSensitiveWord', params)
  },

  //修改敏感词
  editSensitiveWord: params => {
    return http.post('/sensitiveword/editSensitiveWord', params)
  },

  //删除敏感词
  deleteSensitiveWord: params => {
    return http.post('/sensitiveword/delete', params)
  }
}
